import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: { home: Home }
  },
  {
    path: '/search',
    name: 'Search',
    components: { home: Home }
  },
  {
    path: '/la-studios',
    name: 'Las',
    component: () => import(/* webpackChunkName: "production" */ '../views/Las.vue')
  },
  {
    path: '/margarita-mix-hollywood',
    name: 'Mmhw',
    component: () =>
      import(/* webpackChunkName: "post-production" */ '../views/Mmhw.vue')
  },
  {
    path: '/margarita-mix-santa-monica',
    name: 'Mmsm',
    component: () =>
      import(/* webpackChunkName: "post-production" */ '../views/Mmsm.vue')
  },
  {
    path: '/contact/:facility?',
    name: 'ContactFacility',
    component: () => import(/* webpackChunkName: "facility" */ '../views/ContactFacility.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
