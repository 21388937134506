<template>
  <fki-loader :loading="loading" />
  <fki-container
    class="fki-form-container"
  >
    <h3 class="job-title">
      {{ currentJob.title }}
    </h3>
    <p
      style="color:#fff;font-size:13px; text-transform:uppercase; line-height:1; margin-bottom:20px;"
      class="job-details"
    >
      <span
        class="job-id"
        style="color:#fff;"
      >Job ID: {{ currentJob.jobId }} </span>
      <span ng-if="currentJob.department"> | </span>
      <span class="job-department">{{ currentJob.department }}</span>
    </p>
    <form
      id="send-message"
      name="messageForm"
    >
      <div class="row">
        <div class="col">
          <input
            v-model="eMessage.firstname"
            type="text"
            placeholder="First Name*"
            required
            autofocus=""
            class="fki-form-input"
            @blur="v$.eMessage.firstname.$touch()"
          >
          <p
            v-for="error of v$.eMessage.firstname.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for first name
          </p>
          <input
            v-model="eMessage.lastname"
            type="text"
            placeholder="Last Name*"
            required
            autofocus=""
            class="fki-form-input"
            @blur="v$.eMessage.lastname.$touch()"
          >
          <p
            v-for="error of v$.eMessage.lastname.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for last name
          </p>
          <input
            v-model="eMessage.email"
            type="email"
            placeholder="Email*"
            required
            class="fki-form-input"

            @blur="v$.eMessage.email.$touch()"
          >
          <p
            v-for="error of v$.eMessage.email.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for email
          </p>
          <input
            v-model="eMessage.phone"
            type="text"
            placeholder="Phone*"
            required
            class="fki-form-input"
          >
          <p
            v-for="error of v$.eMessage.phone.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for phone
          </p>

          <button
            type="button"
            class="fki-upload"
            @click.stop="onPickResumeFile"
          >
            Upload Resume/CV
          </button>
          <input
            ref="resumeFileInput"
            type="file"
            style="display: none"
            accept=".pdf,.doc,.docx,.rtf,.txt"
            max-size="2MB"
            @change="onResumeFilePicked"
          >
          <p v-if="resumeName">
            Attached: {{ eMessage.resumeText }}
          </p>
          <textarea
            v-model="eMessage.resumeText"
            class="fki-form-textarea"
            placeholder="Paste Resume/CV*"
            name="resumeText"
          />
          <button
            type="button"
            class="fki-upload"
            @click.stop="onPickCoverLetterFile"
          >
            Upload Cover Letter
          </button>
          <input
            ref="coverLetterFileInput"
            type="file"
            style="display: none"
            accept=".pdf,.doc,.docx,.rtf,.txt"
            max-size="2MB"
            @change="onCoverLetterFilePicked"
          >
          <p v-if="coverLetterName">
            Attached: {{ eMessage.coverLetterText }}
          </p>
          <textarea
            v-model="eMessage.coverLetterText"
            class="fki-form-textarea"
            placeholder="Paste Cover Letter"
            ng-show="showPasteCoverLetter"
            name="coverLetterText"
          />
          <div class="recaptch">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3"
              theme="light"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-left col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <button
            type="button"
            class="button"
            :class="{disabled:loading}"
            @click.stop="eMessageSubmit(eMessage)"
          >
            Submit Application
          </button>
        </div>
      </div>
    </form>
  </fki-container>
  <fki-container-popup
    :is-active="popupResponseOpen"
    overlay
    persistent
    color="#424242"
    width="45%"
    @close="closePopupResponse"
  >
    <div
      class="fki-email-message-response"
      :class="messageReponse.error? 'error': 'success'"
    >
      <h3 v-html="messageReponse.message " />
    </div>
  </fki-container-popup>
</template>

<script>
import Api from '@/services/api'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { FKILoader } from '@/components/FKILoader'
import { FKIContainer, FKIContainerPopup } from '../FKIContainer'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'FkiContactFormApply',
  components: {
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'fki-loader': FKILoader,
    'vue-recaptcha': VueRecaptcha
  },
  props: {
    currentJob: {
      type: Object,
      default: undefined
    }
  },
  emits: ['isLoading'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      siteKey: '6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3',
      messageReponse: {},
      popupResponseOpen: false,
      loading: false,
      resumeName: null,
      selectedResumeFile: null,
      coverLetterName: null,
      selectedCoverLetterFile: null,
      eMessage: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        resumeText: '',
        coverLetterText: '',
        department: null,
        jobTitle: null,
        jobId: null,
        subject: null

      }
    }
  },
  validations () {
    return {
      eMessage: {
        firstname: { required },
        lastname: { required },
        phone: { required },
        email: { required, email }
      }
    }
  },
  async created () {},
  methods: {
    onPickResumeFile () {
      this.$refs.resumeFileInput.click()
    },
    async onResumeFilePicked (event) {
      const files = event.target.files
      this.eMessage.resumeText = files[0].name
      this.selectedResumeFile = files[0]

      const formData = new FormData()

      formData.append('file', files[0])

      try {
        this.loading = true
        // this.$emit('isLoading', true)
        const { data: result } = await Api().post('/upload', formData)
        this.resumeName = result.path
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        // this.$emit('isLoading', false)
      }
    },
    onPickCoverLetterFile () {
      this.$refs.coverLetterFileInput.click()
    },
    async onCoverLetterFilePicked (event) {
      const files = event.target.files
      this.eMessage.coverLetterText = files[0].name
      this.selectedCoverLetterFile = files[0]

      const formData = new FormData()

      formData.append('file', files[0])
      try {
        this.loading = true
        // this.$emit('isLoading', true)
        const { data: result } = await Api().post('/upload', formData)
        console.log(result)
        this.coverLetterName = result.path
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        // this.$emit('isLoading', false)
      }
    },
    async eMessageSubmit (fields) {
      console.log(fields)
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.eMessage.subject =
          'Online Application - ' +
          this.currentJob.department +
          ' ' +
          this.currentJob.title +
          ' JobID: ' +
          this.currentJob.jobId
        this.eMessage.department = this.currentJob.department
        this.eMessage.jobTitle = this.currentJob.title
        this.eMessage.jobId = this.currentJob.jobId

        const formData = new FormData()

        formData.append('subject', this.eMessage.subject)
        formData.append('department', this.eMessage.department)
        formData.append('jobTitle', this.eMessage.jobTitle)
        formData.append('jobId', this.eMessage.jobId)
        formData.append('firstname', this.eMessage.firstname)
        formData.append('lastname', this.eMessage.lastname)
        formData.append('email', this.eMessage.email)
        formData.append('phone', this.eMessage.phone)

        console.log(this.selectedResumeFile)
        if (this.selectedResumeFile) {
          formData.append('resumeFile', this.resumeName)
          formData.append('resumeText', this.resumeName)
        } else if (this.eMessage.resumeText) formData.append('resumeText', this.eMessage.resumeText)

        if (this.selectedCoverLetterFile) {
          formData.append('coverLetterFile', this.coverLetterName)
          formData.append('coverLetterText', this.coverLetterName)
        } else if (this.eMessage.coverLetterText) formData.append('coverLetterText', this.eMessage.coverLetterText)

        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
        this.loading = true

        try {
          // this.$emit('isLoading', true)
          const { data: result } = await Api().post('/send/message/apply', formData)
          this.messageReponse = result
          this.popupResponseOpen = true
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
          // this.$emit('isLoading', false)
        }
      }
    },
    handleSuccess () {
      console.log('success')
    },
    handleError () {
      console.log('error')
    },
    closePopupResponse () {
      this.messageReponse = null
      this.popupResponseOpen = false
      this.v$.$reset()
    },
    formatTelephone (input) {
      var inputLow = input.toLowerCase()
      var phonenumber = ''
      for (var i = 0; i < inputLow.length; i++) {
        var character = inputLow.charAt(i)
        switch (character) {
          case '0':
            phonenumber += '0'
            break
          case '1':
            phonenumber += '1'
            break
          case '2':
            phonenumber += '2'
            break
          case '3':
            phonenumber += '3'
            break
          case '4':
            phonenumber += '4'
            break
          case '5':
            phonenumber += '5'
            break
          case '6':
            phonenumber += '6'
            break
          case '7':
            phonenumber += '7'
            break
          case '8':
            phonenumber += '8'
            break
          case '9':
            phonenumber += '9'
            break
          case 'a':
          case 'b':
          case 'c':
            phonenumber += '2'
            break
          case 'd':
          case 'e':
          case 'f':
            phonenumber += '3'
            break
          case 'g':
          case 'h':
          case 'i':
            phonenumber += '4'
            break
          case 'j':
          case 'k':
          case 'l':
            phonenumber += '5'
            break
          case 'm':
          case 'n':
          case 'o':
            phonenumber += '6'
            break
          case 'p':
          case 'q':
          case 'r':
          case 's':
            phonenumber += '7'
            break
          case 't':
          case 'u':
          case 'v':
            phonenumber += '8'
            break
          case 'w':
          case 'x':
          case 'y':
          case 'z':
            phonenumber += '9'
            break
          case '-':
          case ' ':
          case '(':
          case ')':
            phonenumber += ''
            break
          default:
            phonenumber += character
        }
      }
      return phonenumber
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .fki-email-message-response{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      width:100%;
      flex-direction: column;
      font-size: 14px;
      padding: 12px 24px;
      &.error {
        background-color: #ff0000;
      }
      &.success {
        background-color: #628f2f;
      }
      p {
        font-size: 14px;
      }
      h3 {
        color:#fff;
        text-align: center;
        font-size: 16px;
        &.error {
          color: #ff0000;
        }
      }
    }
    .fki-form-container {
      display: flex;
      margin-top: 30px;
      clear: none;
      width: 100%;
      min-width: 300px;
      position: relative;
      overflow: auto;
      height: 100%;
      min-height: 100px;
      // max-width: 924px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        width: 100%;
        height: 45px;
        background-color: #E77926;
        color: #fff;
        border: 0;
        outline: 0;
        padding: 10px 15px;
        margin: 10px 0;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &:hover {
          background-color: #fff;
          color: #E77926;
          transition: all .55s cubic-bezier(.25,.8,.25,1);
        }
      }
      h3 {
        padding-top: 5px;
        text-align: left;
        margin: 0;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        margin: 0 0 8px;
      }
      .fki-form-input {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
      }
      .fki-form-textarea {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        height: 140px;
        background: #fff;
        color: #757575;
        border: 0;
        outline: 0;
        font-size: 14px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
      }
      .fki-dropdown {
        width: 100%;
        padding: 0;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        position: relative;
        float: left;
        cursor: pointer;
        p {
          color: #757575;
          font-size: 14px;
          margin: 0;
          padding: 13px 10px;
        }
        .fki-dropdown-content {
          opacity: 0;
          max-height: 175px;
          overflow: auto;
          display: none;
          height: 0;
          position: absolute;
          top: 0;
          padding: 8px 0;
          border: 1px solid #eee;
          z-index: 5000;
          box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
          &.open {
            display: block;
            opacity: 1;
            height: auto;
            position: absolute;
            min-height:45px;
            background: #fff;
            width: 100%;
            margin: 0;
            padding: 8px 0;
            left: 0;
            border: 1px solid #d3d3d3;
            box-sizing: border-box;
            top: 0;
          }
        }
        .fki-option {
          color: #757575;
          font-size: 14px;
          margin: 3px 0 0;
          padding: 8px;
          cursor: pointer;
          &:hover {
            cursor: pointer;
            background: #eee;
          }
        }
      }
      .fki-popup-contact-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: bold;
}
  .fki-popup-contact-info {
    float: left;
    margin-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top:10px;
    a {
    clear: none;
    display: inline;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: bold;
}
}
      .right-arrow:before {
        position: absolute;
        color: #757575;
        right: 8px;
        top: 12px;
        content: '\25BC';
      }
      .fki-dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 4999;
        cursor: auto !important;
    }

    form {
      width:100%;
    }
   .recaptch {
      padding: 10px 0 20px;
      max-height: 80px !important;
      overflow: hidden;
      width: 100%;
      margin-bottom:20px;
    }
    .error {
      color: #ff0000;
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0;
    }

     .row {
      display: flex;
      width: 100%;
      text-align: left;
      justify-content: space-between;
      .col {
        display: flex;
        width: 100%;
        padding-right: 20px;
        flex-direction: column;
        &.col-right {
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }

}

</style>
