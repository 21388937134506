<template>
  <fki-loader :loading="loading" />
  <fki-container
    class="fki-form-container"
  >
    <h3>Send us a message</h3>
    <form
      id="send-message"
      name="messageForm"
    >
      <div class="row">
        <div class="col">
          <input
            v-model="eMessage.name"
            type="text"
            placeholder="Name*"
            required
            autofocus=""
            class="fki-form-input"
            @focus="closeDropdown()"
            @blur="v$.eMessage.name.$touch()"
          >
          <p
            v-for="error of v$.eMessage.name.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for name
          </p>
          <input
            v-model="eMessage.email"
            type="email"
            placeholder="Email*"
            required=""
            class="fki-form-input"
            @focus="closeDropdown()"
            @blur="v$.eMessage.email.$touch()"
          >
          <p
            v-for="error of v$.eMessage.email.$errors"
            :key="error.$uid"
            class="error"
          >
            {{ error.$message }} for email
          </p><input
            v-model="eMessage.phone"
            type="text"
            placeholder="Phone"
            class="fki-form-input"
            @focus="closeDropdown()"
          >
        </div>
        <div class="col col-right">
          <input
            v-model="eMessage.department"
            hidden
            placeholder="Choose Service / Department"
          > <div class="fki-dropdown">
            <div
              v-if="showDepartmentDropdown"
              class="fki-dropdown-overlay"
              @click="closeDropdown()"
            /> <p
              v-if="!showDepartmentDropdown"
              class="right-arrow"
              @click="openDropdown('department')"
            >
              {{ eMessage.department }}
            </p>
            <div
              v-if="showDepartmentDropdown"
              class="fki-dropdown-content"
              :class="{open:showDepartmentDropdown}"
            >
              <p
                v-for="item, index in categories"
                :key="index"
                class="fki-option"
                @click="selectValue(item)"
              >
                {{ item.description }}
              </p>
            </div>
          </div>
          <input
            v-model="eMessage.subject"
            hidden
            placeholder="Choose Subject"
          >
          <div
            v-if="eMessage.department !== 'Choose Service'"
            class="fki-dropdown"
          >
            <div
              v-if="showSubjectDropdown"
              class="fki-dropdown-overlay"
              @click="closeDropdown()"
            />
            <p
              v-if="!showSubjectDropdown"
              class="right-arrow"
              @click="openDropdown('subject')"
            >
              {{ eMessage.subject }}
            </p>
            <div
              v-if="showSubjectDropdown"
              class="fki-dropdown-content"
              :class="{open:showSubjectDropdown}"
            >
              <p
                v-for="item, index in subjects"
                :key="index"
                class="fki-option"
                @click="selectSubject(item)"
              >
                {{ item }}
              </p>
            </div>
          </div>
          <textarea
            v-model="eMessage.message"
            maxlength="500"
            placeholder="Message"
            class="fki-form-textarea"
            @focus="closeDropdown()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-left col">
          <div class="recaptch">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3"
              theme="light"
            />
          </div>
        </div>
        <div class="col-right col">
          <div
            v-for="contact, i in contacts"
            :key="i"
            class="fki-popup-contact-info-container"
          >
            <div class="fki-popup-contact-info left">
              <span class="fki-popup-contact-title">{{ contact.name }}: </span>
              <span>
                <a
                  :href="`tel:+${formatTelephone(contact.telephones[0].number)}`"
                >+{{ contact.telephones[0].number }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-left col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <button
            type="button"
            class="button"
            :class="{disabled:loading}"
            @click.stop="eMessageSubmit(eMessage)"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </fki-container>
  <fki-container-popup
    :is-active="popupResponseOpen"
    overlay
    persistent
    has-padding
    color="#424242"
    width="40%"
    @close="closePopupResponse"
  >
    <div class="fki-email-message-response">
      <h3 :class="messageReponse.errors? 'error': ''">
        {{ messageReponse.message }}
      </h3>
    </div>
  </fki-container-popup>
</template>

<script>
import Api from '@/services/api'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { FKILoader } from '@/components/FKILoader'
import { FKIContainer, FKIContainerPopup } from '../FKIContainer'
import { VueRecaptcha } from 'vue-recaptcha'
// import { createDeviceDetector } from 'next-vue-device-detector'

// export const device = createDeviceDetector()

export default {
  name: 'FkiContactForm',
  components: {
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'fki-loader': FKILoader,
    'vue-recaptcha': VueRecaptcha
  },
  props: {
    currentFacility: {
      type: Object,
      default: undefined
    },
    currentService: {
      type: String,
      default: undefined
    },
    currentSubject: {
      type: String,
      default: undefined
    }

  },
  emits: ['isLoading'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      siteKey: '6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3',
      messageReponse: {},
      popupResponseOpen: false,
      loading: false,
      categories: [],
      subjects: [],
      showDepartmentDropdown: false,
      showSubjectDropdown: false,
      contacts: {},
      eMessage: {
        name: null,
        email: null,
        phone: null,
        department: 'Choose Service',
        subject: 'Choose Subject',
        message: null
      }
    }
  },
  validations () {
    return {
      eMessage: {
        name: { required },
        email: { required, email }
      }
    }
  },
  async created () {
    await this.getcCategories()
    await this.getContacts()
    if (this.currentService) this.eMessage.department = this.currentService
    if (this.currentSubject) this.eMessage.subject = this.currentSubject
  },
  methods: {
    closeEmailResponse () {
      this.popupResponseOpen = false
      this.messageReponse = null
    },
    async eMessageSubmit (fields) {
      console.log(fields)
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.loading = true

        try {
          // this.$emit('isLoading', true)
          const { data: result } = await Api().post('/send/message', fields)
          this.messageReponse = result
          this.popupResponseOpen = true
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
          // this.$emit('isLoading', false)
        }
      }
    },
    handleSuccess () {
      console.log('success')
    },
    handleError () {
      console.log('error')
    },
    async getServices (identifier) {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/services/c/' + identifier)

        this.subjects = []
        for (var i = 0; i < result.length; i++) {
          for (var j = 0; j < result[i].items.length; j++) {
            this.subjects.push(result[i].items[j].name)
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    async getContacts () {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/contact')
        this.items = []
        this.contacts = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    async getcCategories () {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/category/form')

        this.categories = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    selectValue (value) {
      this.eMessage.subject = 'Choose Subject'
      this.eMessage.department = value.description
      this.getServices(value.identifier)

      this.closeDropdown()
    },
    selectSubject (value) {
      this.eMessage.subject = value
      this.closeDropdown()
    },

    closePopupResponse () {
      this.messageReponse = null
      this.popupResponseOpen = false
    },
    openDropdown (name) {
      if (name === 'subject') {
        this.showSubjectDropdown = true
      }
      if (name === 'department') {
        this.showDepartmentDropdown = true
      }
    },
    closeDropdown () {
      this.showSubjectDropdown = false
      this.showDepartmentDropdown = false
    },
    formatTelephone (input) {
      var inputLow = input.toLowerCase()
      var phonenumber = ''
      for (var i = 0; i < inputLow.length; i++) {
        var character = inputLow.charAt(i)
        switch (character) {
          case '0':
            phonenumber += '0'
            break
          case '1':
            phonenumber += '1'
            break
          case '2':
            phonenumber += '2'
            break
          case '3':
            phonenumber += '3'
            break
          case '4':
            phonenumber += '4'
            break
          case '5':
            phonenumber += '5'
            break
          case '6':
            phonenumber += '6'
            break
          case '7':
            phonenumber += '7'
            break
          case '8':
            phonenumber += '8'
            break
          case '9':
            phonenumber += '9'
            break
          case 'a':
          case 'b':
          case 'c':
            phonenumber += '2'
            break
          case 'd':
          case 'e':
          case 'f':
            phonenumber += '3'
            break
          case 'g':
          case 'h':
          case 'i':
            phonenumber += '4'
            break
          case 'j':
          case 'k':
          case 'l':
            phonenumber += '5'
            break
          case 'm':
          case 'n':
          case 'o':
            phonenumber += '6'
            break
          case 'p':
          case 'q':
          case 'r':
          case 's':
            phonenumber += '7'
            break
          case 't':
          case 'u':
          case 'v':
            phonenumber += '8'
            break
          case 'w':
          case 'x':
          case 'y':
          case 'z':
            phonenumber += '9'
            break
          case '-':
          case ' ':
          case '(':
          case ')':
            phonenumber += ''
            break
          default:
            phonenumber += character
        }
      }
      return phonenumber
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .fki-email-message-response{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width:100%;
      h3 {
        color:#fff;
        text-align: center;
        font-size: 16px;
        &.error {
          color: #ff0000;
        }
      }
    }
    .fki-form-container {
      display: flex;
      margin-top: 30px;
      clear: none;
      width: 100%;
      min-width: 300px;
      position: relative;
      overflow: auto;
      height: 100%;
      min-height: 100px;
      // max-width: 924px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        width: 100%;
        height: 45px;
        background-color: #E77926;
        color: #fff;
        border: 0;
        outline: 0;
        padding: 10px 15px;
        margin: 10px 0;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &:hover {
          background-color: #fff;
          color: #E77926;
          transition: all .55s cubic-bezier(.25,.8,.25,1);
        }
      }
      h3 {
        padding-top: 5px;
        text-align: left;
        margin: 0;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        margin: 0 0 8px;
      }
      .fki-form-input {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
      }
      .fki-form-textarea {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        height: 140px;
        background: #fff;
        color: #757575;
        border: 0;
        outline: 0;
        font-size: 14px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
      }
      .fki-dropdown {
        width: 100%;
        padding: 0;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        position: relative;
        float: left;
        cursor: pointer;
        p {
          color: #757575;
          font-size: 14px;
          margin: 0;
          padding: 13px 10px;
        }
        .fki-dropdown-content {
          opacity: 0;
          max-height: 175px;
          overflow: auto;
          display: none;
          height: 0;
          position: absolute;
          top: 0;
          padding: 8px 0;
          border: 1px solid #eee;
          z-index: 5000;
          box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
          &.open {
            display: block;
            opacity: 1;
            height: auto;
            position: absolute;
            min-height:45px;
            background: #fff;
            width: 100%;
            margin: 0;
            padding: 8px 0;
            left: 0;
            border: 1px solid #d3d3d3;
            box-sizing: border-box;
            top: 0;
          }
        }
        .fki-option {
          color: #757575;
          font-size: 14px;
          margin: 3px 0 0;
          padding: 8px;
          cursor: pointer;
          &:hover {
            cursor: pointer;
            background: #eee;
          }
        }
      }
      .fki-popup-contact-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: bold;
}
  .fki-popup-contact-info {
    float: left;
    margin-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top:10px;
    a {
    clear: none;
    display: inline;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: bold;
}
}
      .right-arrow:before {
        position: absolute;
        color: #757575;
        right: 8px;
        top: 12px;
        content: '\25BC';
      }
      .fki-dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 4999;
        cursor: auto !important;
    }

    form {
      width:100%;
    }
   .recaptch {
      padding: 10px 0;
      max-height: 80px !important;
      overflow: hidden;
      width: 100%;
      margin-bottom:10px;
    }
    .error {
      color: #ff0000;
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0;
    }

     .row {
      display: flex;
      width: 100%;
      text-align: left;
      flex-direction: column;
      justify-content: space-between;
      .col {
        display: flex;
        width: 100%;
        padding-right: 0;
        flex-direction: column;
        padding-left:0;
        &.col-right {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

}

@media screen and (min-width: 1040px) {
  .row {
    flex-direction: row !important;
    .col  {
      width: 50% !important;
      padding-right: 20px;
      &.col-right {
        width: 50% !important;
        padding-right: 0;
        padding-left: 20px !important;
      }
      &.col-left {
        width: 50% !important;
        padding-left: 0;
        padding-right: 20px !important ;
      }
    }
  }
}
</style>
