<template>
  <div
    v-if="isActive"
    id="nav"
    class="fki-navbar"
    role="navigation"
  >
    <div
      class="hamburger"
      :class="navOpen?'closed':'open'"
      @click="openMenu()"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.34 15.32"
      >
        <title>menu</title>
        <line
          x1="14.59"
          y1="7.66"
          x2="0.75"
          y2="7.66"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          x1="14.59"
          y1="0.75"
          x2="0.75"
          y2="0.75"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          x1="14.59"
          y1="14.57"
          x2="0.75"
          y2="14.57"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
      </svg>
    </div>
    <div
      class="close-mobile"
      :class="navOpen?'open':'closed'"
      @click="closeMenu()"
    >
      <svg
        id="38005175-180f-4794-8dcf-1de6492662f7"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29.2 29.2"
      >
        <title>Vector Smart Object10</title>
        <line
          class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
          x1="0.53"
          y1="0.53"
          x2="28.67"
          y2="28.67"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
          x1="0.53"
          y1="28.67"
          x2="28.67"
          y2="0.53"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
      </svg>
    </div>
    <div
      class="small flex-container center-center"
    >
      <ul
        class="fki-nav fki-navbar-nav-mobile"
        :class="navOpen?'open':'closed'"
      >
        <li
          v-for="item, index in items"
          :key="index"
          class="fki-nav-item"
          @click="closeMenu()"
        >
          <router-link
            :to="`/${item.slug}`"
            :class="createLinkClass(item)"
            class="fki-nav-item-link"
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div
      v-if="isActive"
      class="large flex-container center-center"
    >
      <ul class="fki-nav fki-navbar-nav">
        <li
          v-for="item, index in items"
          :key="index"
          class="fki-nav-item"
        >
          <router-link
            :to="`/${item.slug}`"
            :class="createLinkClass(item)"
            class="fki-nav-item-link"
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FkiNavbar',
  props: {
    items: {
      default: () => [],
      type: Array
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isMobile: Boolean
  },
  data () {
    return { navOpen: false }
  },

  methods: {
    openMenu () {
      this.navOpen = true
    },
    closeMenu () {
      this.navOpen = false
    },
    createLinkClass (item) {
      let isActive = ''
      const slug = this.$route.path.split('/')
      if (slug[1] === item.slug) isActive = 'is-active'
      return `${item.color} ${isActive}`
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  .fki-navbar {
    display: block;
    position: relative;
   height: auto;
   padding-top:20px;
   width:auto;
    .hamburger, .close-mobile{
     width: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 20px 20px 20px;
      &.open{
        display: block;
      }
       &.closed{
        display: none;
      }
    }
    .fki-nav {
      list-style: none;
      display: flex;
      justify-content: center;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      padding: 0;
      display:none;
      &.fki-navbar-nav-mobile {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -110%;
        right: 0;
        width: 100%;
        padding-top: 150px;
        justify-content: flex-start;
        align-items: center;
        background-color: #000;
        height: 100%;
        margin: 0;
        opacity: 0.95;
        transition: left .55s cubic-bezier(.25,.8,.25,1);
        &.open {
           transition: left .55s cubic-bezier(.25,.8,.25,1);
          left: 0;
        }
        .fki-nav-item {
          margin: 10px 0;
          .fki-nav-item-link {
            text-decoration: none;
            color:#fff;
            font-size: 16px;
             font-family:  Harman-Script, "Helvetica Neue", Helvetica, Arial, sans-serif;
          }
        }
      }
      .fki-nav-item {
        display: block;
        float: left;
        position: relative;
        text-align: center;
        clear: none;
        height:34px;
        margin: 0 2.24vw;
        margin: 0;
        // text-transform: uppercase;
        .fki-nav-item-link {
          text-decoration: none;
          color:#fff;
          font-size: 16px;
          // font-weight: bold;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
          font-family:  Harman-Script, "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
      }
    }
    .fki-nav .fki-nav-item{
      .fki-nav-item-link{
        letter-spacing: 1.25px;
        line-height: 2rem;
        font-size: 1.2rem;
        font-size: 16px;
        height: 34px;
        vertical-align: middle;
        display: inline-block;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &.router-link-exact-active, &.router-link-active, &.is-active {
          &.yellow{
          color: $fki-yellow;
          }
          &.green{
            color: $fki-green;
          }
          &.blue{
            color: $fki-blue;
          }
          &.pink{
            color: $fki-pink;
          }
          &.purple{
            color: $fki-purple;
          }
          &.orange{
            color: $fki-orange;
          }
        }
      }
      &:hover{
        .fki-nav-item-link.yellow{
        color: $fki-yellow;
        }
        .fki-nav-item-link.green{
          color: $fki-green;
        }
        .fki-nav-item-link.blue{
          color: $fki-blue;
        }
        .fki-nav-item-link.pink{
          color: $fki-pink;
        }
        .fki-nav-item-link.purple{
          color: $fki-purple;
        }
        .fki-nav-item-link.orange{
          color: $fki-orange;
        }
      }
    }
  }

@media screen and (min-width: 768px) {
  .fki-navbar {
    width: 100%;
    width: 82%;
    margin: 0 auto;
    .hamburger {display:none !important;}
    .fki-nav {
      display: block;
      margin: 0;
      padding: 0;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      transform: translate(0,0);
      transition: transform .55s,opacity .5s ease;
    }
  }
}
</style>
