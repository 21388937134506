<template>
  <div
    v-if="isActive"
    class="fki-header"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FkiHeader',
  props: {
    isActive: Boolean,
    isMobile: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-header {
  position: fixed;
  display: flex;
  flex-direction: column;
  // border-bottom:1px solid rgba(255, 255, 255, 0.12);
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  padding: 15px;
  height: 195px;
  align-items: center;
  min-height: 1em;
  justify-content: flex-start;
  overflow:hidden;
}
.home.fki-header {
  border-bottom: none;
}

@media screen and (min-width: 768px) {
}
</style>
