<template>
  <div class="fki-page-slideshow">
    <vueper-slides
      fade
      :pause-on-hover="false"
      :bullets="false"
      :autoplay="autoplay"
      :duration="duration"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :content="slide.credit"
        :image="'/' + slide.src"
      >
        <template #content>
          <div
            class="vueperslide__content-wrapper"
          >
            <div
              v-if="shadow"
              class="fki-slideshow-shadow"
            />
            <div
              v-if="overlay"
              class="fki-slideshow-overlay"
            />
            <div
              v-if="!isPaused"
              class="fki-slide-text"
            >
              <h3>{{ slide.title }}</h3>
              <p v-html="slide.credit" />
              <span class="fki-slide-copyright">
                &copy; {{ slide.copyright }}
              </span>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'FkiPageSlide',
  components: { VueperSlides, VueperSlide },
  props: {
    title: {
      default: '',
      type: String
    },
    duration: {
      default: 6000,
      type: Number
    },
    autoplay: {
      default: true,
      type: Boolean
    },
    isPaused: {
      default: false,
      type: Boolean
    },
    slides: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => {},
      type: Object
    },
    shadow: {
      default: false,
      type: Boolean
    },
    overlay: {
      default: false,
      type: Boolean
    }
  }
  // setup (props) {
  //   console.log(props.title)
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-page-slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  z-index: 104;
  display: none;
  .fki-slide-text {
    z-index: 105;
    display: flex;
    position: absolute;
    padding-bottom: 0;
    text-align: center;
    color: #fff;
    padding-top: 0;
    bottom: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    h3 {
      font-size: 2.4rem;
      margin: 5px 0;
      opacity: 0.78;
    }
    p {
      font-size: 1.8rem;
       margin: 0;
       opacity: 0.78;
    }
  }
  .fki-slide-copyright {
    font-size: 0.76vw;
    padding-right: 20px;
    z-index: 105;
    opacity: 0.78;
    width: 100%;
    font-size: 10px;
    color: #fff;
    padding-top: 15px;
    text-align: right;
    margin: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }
    // .fki-slide-watermark {
    //    // display: none;
    // }
}

 @media screen and (min-width: 768px ) and (min-height: 768px) {
    .fki-page-slideshow {
      display: block !important;
    }
 }

</style>
