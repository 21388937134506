import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createDeviceDetector } from 'next-vue-device-detector'
import '../src/assets/css/styles.scss'

export const device = createDeviceDetector()

createApp(App)
  .use(router)
  .use(device)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCERe7o4yMTLtU67SlueuVLdCezBZpBDYc'
    }
  })
  .mount('#app')
