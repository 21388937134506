<template>
  <fki-loader :loading="isLoading" />
  <fki-full-slide
    v-if="backgrounds.length"
    :slides="backgrounds"
    :overlay="slideOverlay"
    :is-paused="isPaused"
    :autoplay="!isPaused"
    :init-slide="initSlide"
    :shadow="hasShadow"
    :tv="isTV"
  />
  <fki-header
    :is-active="true"
    :class="$route.name === 'Home'? 'home' : ''"
  >
    <fki-navbar-header
      :is-active="true"
      :logo="logo"
    />
    <fki-navbar
      v-if="!hideFeatures"
      :is-active="true"
      :items="pages"
    />
  </fki-header>
  <router-view
    name="home"
    :items="homeItems"
    @doSearch="doSearch"
    @clearSearch="clearSearch"
    @isLoading="setIsLoading"
  />
  <router-view
    @isLoading="setIsLoading"
    @setBackgrounds="setBackgrounds"
  />
</template>

<script>
import Api from '@/services/api'
import { FKILoader } from '@/components/FKILoader'
import { FKIFullSlide } from '@/components/FKISlides'
import { FKIHeader } from '@/components/FKIApp'
import { FKINavbar, FKINavbarHeader } from '@/components/FKINav'
import logo from '@//assets/LAS-MM-Logo.png'

export default {
  name: 'App',
  title: 'App',
  components: {
    'fki-header': FKIHeader,
    'fki-navbar': FKINavbar,
    'fki-navbar-header': FKINavbarHeader,
    'fki-full-slide': FKIFullSlide,
    'fki-loader': FKILoader
  },
  data: () => ({
    isLoading: false,
    logo: logo,
    slideOverlay: false,
    hasShadow: false,
    isPaused: false,
    hideFeatures: false,
    initSlide: 1,
    isTV: false,
    backgrounds: [],
    pages: [],
    homeItems: []
  }),
  watch: {
    $route: 'checkParams'
  },
  async created () {
    await this.getPages()

    await this.getBackgrounds()

    this.initSlide = Math.floor(Math.random() * this.backgrounds.length)

    await this.checkParams()
  },
  methods: {

    setIsLoading (b) {
      this.isLoading = b
    },
    async getPages () {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/pages')
        this.pages = result
        this.homeItems = this.pages.filter(element => {
          return element.slug !== 'contact'
        })
      } catch (err) {
        console.log(err)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    async getBackgrounds () {
      try {
        const { data: result } = await Api().get('/backgrounds')
        this.backgrounds = result
      } catch (err) {
        console.log(err)
      }
    },
    async setBackgrounds (slides) {
      console.log('set backgrounds')
      this.backgrounds = slides
    },
    doSearch (e) {
      this.slideOverlay = true
      this.isPaused = true
    },
    clearSearch (e) {
      this.slideOverlay = false
      this.isPaused = false
    },
    async checkParams () {
      this.isPaused = false
      this.slideOverlay = true
      this.hasShadow = true
      this.hideFeatures = false
      this.isTV = false
      if (this.$route.name === 'ContactFacility') {
        this.slideOverlay = true
      }
      if (this.$route.name === 'Home') {
        this.hideFeatures = true
      }
      this.initSlide = Math.floor(Math.random() * this.backgrounds.length)
    }
  }
}
</script>

<style lang="scss">
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  *, :after, :before {
      box-sizing: border-box;
  }
  html {
    font-size:10px;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    width:100%;
    height: 100%;
    background-color: #000000;
  }

  p, a, span, h1, h2, h3, h4, h5, h6 {
    &.yellow{
      color: $fki-yellow;
    }
    &.green{
      color: $fki-green;
    }
    &.blue{
      color: $fki-blue;
    }
    &.pink{
      color: $fki-pink;
    }
    &.purple{
      color: $fki-purple;
    }
    &.orange{
      color: $fki-orange;
    }
 }
  .underline {
    text-decoration: underline;
  }

  .capitalize {
    text-transform: uppercase;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    width:100%;
    height:auto;
    position: relative;
  }

  // .home {
  //   display: flex;
  //   position: relative;
  // }

  .fki-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    min-height: 100%;
  }

  @media screen and (min-width: 768px ) {
    .fki-page {
      top: 131px;
    }
  }

  /* Animations */
  @keyframes swipeDown {
    50%,
    60% {
      top: 0;
    }
    100% {
      top: -100%;
    }
  }

  @keyframes fadeOut {
    50%,
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes showMe {
    from {
      opacity: 0;
    }
    60%,
    100% {
      opacity: 1;
    }
  }
</style>
